import { FC, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router';
import {
  Alert,
  Avatar,
  Box,
  Grid,
  Paper,
  styled,
  Typography
} from "@mui/material";
import useCurriculumDetail from 'Hooks/useCurriculumDetail';
import { useLocation } from 'react-router-dom';
import SecondButton from '../atom/Buttons/SecondButton';
import DefaultButton from '../atom/Buttons/DefaultButton';
import BackdropLording from '../comm/BackDrop';
import { TClassStudent } from '../../Types/TStudent';
import ThirdButton from '../atom/Buttons/ThirdButton';
import StudentReserve from "../Organisms/StudentReserve";
import { MessageContext } from "../MessageContext";

type IBookDetailParam = {
  id: string;
};
const SBox = styled(Paper)({
  textAlign: 'center',
  padding: 20,
  margin: '1em 0',
});

const CurriculumDetail: FC = () => {
  const {
    curriculum,
    onStudents,
    cancelStudents,
    students,
    fetchRequestDownload,
    setCurriculumId,
    fetchRequest,
    handlePersonAdd,
    handlePersonCancel,
    isLoading,
    systemMessage,
  } = useCurriculumDetail();
  const messageContext = useContext(MessageContext)

  const [searchStudents, setSearchStudents] = useState<TClassStudent[]>([]);
  const location = useLocation();

  const { id } = useParams() as IBookDetailParam;
  const navigate = useNavigate();
  const handleSelectUser = (userId: number) => {
    handlePersonAdd(userId);
  };
  const handGoToBack = () => {
    if (curriculum) {
      if (location.state) navigate(-1);
      const date = new Date(curriculum.lecture_date);
      navigate(`/books/${date.getFullYear()}/${date.getMonth() + 1}`);
    }
  };

  const isReserve = (): boolean => {
    if (curriculum) {
      const lectureDate = new Date(curriculum.lecture_date);
      const today = new Date();
      if (lectureDate >= today) return true;
    }

    return false;
  };

  const isReserveButton = (): boolean => {
    if (curriculum) {
      if (onStudents.length < curriculum.service_limit) return true;
    }

    return false;
  };

  useEffect(() => {
    fetchRequest(id);
    setCurriculumId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setSearchStudents(students);
  }, [onStudents, students]);

  useEffect(() => {
    if(systemMessage !== undefined) messageContext.handleSetMessage(systemMessage.text)
    // eslint-disable-next-line
  }, [systemMessage?.text])
  const dateJp = (d: Date) => `${d.getMonth() + 1}月${d.getDate()}日`;
  const dateTimeJp = (d: Date) =>
    `${d.getMonth() + 1}月${d.getDate()}日${d.getHours()}時${d.getMinutes()}分`;

  if (isLoading)
    return (
      <>
        <BackdropLording showBackdrop={isLoading} />
      </>
    );

  return (
    <>
      <Box>
        <Grid container alignItems="center">
          <Grid item md={8} xs={12}>
            {curriculum && (
              <Typography variant="h5">
                {curriculum?.lecture_date &&
                  dateJp(new Date(curriculum.lecture_date))}{' '}
                {curriculum?.name} 予約状況(提供人数：
                {`${curriculum && curriculum.service_limit}人`})
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={4} justifyContent="flex-end">
            <Typography
              sx={{
                my: { xs: 2, md: 0 },
                textAlign: { xs: 'center', md: 'right' },
              }}
            >
              {curriculum?.lecture_date && (
            <>
              <SecondButton
                name="bookHistory"
                onClick={() => navigate(`/bookHistory/${id}`)}
                label="予約履歴"
              />&nbsp;
                <SecondButton
                  name="goBack"
                  onClick={handGoToBack}
                  label="戻る"
                />
            </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: '5en' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          予約人数
          {curriculum &&
            onStudents?.filter(
              (value) => Number(value.book_rank) <= curriculum.service_limit,
            ).length}
          人
        </Typography>
        {curriculum &&
          onStudents?.filter(
            (value) => Number(value.book_rank) <= curriculum.service_limit,
          ).length === 0 && (
            <Alert sx={{ mt: 2, mb: 4 }} severity="info">
              予約はありませんでした。
            </Alert>
          )}
        <Grid
          container
          justifyContent="flex-start"
          spacing={{ xs: 1, md: 1 }}
          sx={{ my: '5em' }}
        >
          {curriculum &&
            onStudents?.map((student, index) => (
              <Grid item key={student.id} xs={12} md={3}>
                <SBox>
                  <Avatar>{index + 1}</Avatar>
                  {student.name}
                  <Typography sx={{ my: 2 }}>
                    {student.book_created_at &&
                      dateTimeJp(new Date(student.book_created_at))}
                  </Typography>
                  {student.book_id && isReserve() && (
                    <Typography>
                      <ThirdButton
                        name={`cancelButton${student.id}`}
                        onClick={() =>
                          student.book_id && handlePersonCancel(student.book_id)
                        }
                        label="キャンセル"
                      />
                    </Typography>
                  )}
                </SBox>
              </Grid>

              // </Link> |</p>
            ))}
        </Grid>
      </Box>
      {cancelStudents && (
        <Box sx={{ marginBottom: '5en' }}>
          {curriculum && (
            <Typography variant="h6" sx={{ mb: 2 }}>
              キャンセル待ち{cancelStudents.length}人
            </Typography>
          )}
          {curriculum && cancelStudents.length === 0 && (
            <Alert sx={{ mt: 2, mb: 4 }} severity="info">
              キャンセル待ちはありませんでした。
            </Alert>
          )}
          <Grid
            container
            justifyContent="flex-start"
            spacing={{ xs: 1, md: 1 }}
          >
            {curriculum &&
              cancelStudents?.map((student, index) => (
                <Grid item key={student.id} xs={12} md={3}>
                  <SBox>
                    <Avatar>{index + 1}</Avatar>
                    {student.name}
                    <Typography sx={{ my: 2 }}>
                      {student.book_created_at &&
                        dateTimeJp(new Date(student.book_created_at))}
                    </Typography>
                    {student.book_id && isReserve() && (
                      <>
                        {isReserveButton() && (
                          <Typography sx={{ my: 3 }}>
                            <DefaultButton
                              name={`cancelButton${student.id}`}
                              onClick={() => handleSelectUser(student.id)}
                              label="予約済みにする"
                            />
                          </Typography>
                        )}
                        <Typography>
                          <ThirdButton
                            name={`cancelButton${student.id}`}
                            onClick={() =>
                              student.book_id &&
                              handlePersonCancel(student.book_id)
                            }
                            label="キャンセル"
                          />
                        </Typography>
                      </>
                    )}
                  </SBox>
                </Grid>

                // </Link> |</p>
              ))}
          </Grid>
        </Box>
      )}
      <Typography sx={{ textAlign: 'right' }}>
        <DefaultButton
          label="メール送信"
          onClick={() => {
            fetchRequestDownload(id);
          }}
        />
      </Typography>
      {isReserve() && (
        <>
          <Typography variant="h6" sx={{ mt: 4 }}>
            利用者一覧
          </Typography>
          {searchStudents && (
            <StudentReserve
              isReserve={isReserveButton()}
              students={searchStudents}
              studentsUpdate={setSearchStudents}
              onBook={handleSelectUser}
              cancelBook={handlePersonCancel}
            />
          )}
        </>
      )}
    </>
  );
};
export default CurriculumDetail;
