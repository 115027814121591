import {AxiosError} from 'axios';
import { useState } from "react";
import useHttp from "./useHttp";
import useHandleError from "./useHandleError";
import { IBookHistory, IUseBookHistories } from "../Types/Response";


const useBookHistories = () => {
  const [bookHistories, setBookHistories] = useState<IUseBookHistories>({
    bookHistories: [],
    error: null,
    isLoading: false,
    systemMessage: undefined
    
  });
  const {client} = useHttp();
  const {handleError} = useHandleError();

  const fetchRequest = (curriculumId: string) => {
    const c = client();
    setBookHistories(prevState => ({...prevState, isLoading: true}));
    c.get<{bookHistories: IBookHistory[]}>(`curriculums/history/${curriculumId ?? 0}`)
      .then((res) => {
        setBookHistories({
          bookHistories: res.data.bookHistories,
          error: null,
          isLoading: false,
          systemMessage: {text: "予約履歴を読み込みました。", isDelete: true}
        });
      }).catch((error: AxiosError) => {
      handleError(error);
    });
  }

  return {...bookHistories, fetchRequest};
};
export default useBookHistories;