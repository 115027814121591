import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';
import {MessageProvider} from 'Components/MessageContext';
import GenericTemplate from "Components/Templates/GenericTemplate";
import Theme from "Components/ThemeProvider";
import PrivateRoute from "Components/PrivateRoute";
import Login from "Components/Pages/Login";
import Home from "Components/Pages/Home";
import AuthUserProvider from "Components/AuthUserProvider";
import StudentDetail from "Components/Pages/StudentDetail";
import Students from "Components/Pages/Students";
import CurriculumDetail from "Components/Pages/CurriculumDetail";
import Curriculums from "Components/Pages/Curriculums";
import Books from "Components/Pages/Books";
import Attendances from "Components/Pages/Attendances";
import AtendanceDetail from "Components/Pages/AtendanceDetail";
import HearingSheet from "./Components/Pages/HearingSheet";
import Absent from "./Components/Pages/Absent";
import Error from "./Components/Pages/Error";
import StudentBooks from "./Components/Pages/StudentBooks";
import TestPage from "./Components/Pages/TestPage";
import BookHistories from "./Components/Pages/BookHistories";
//
// Sentry.init({
//   dsn: "https://78ee18e07f1d40ceb959b30933635c43@o1096680.ingest.sentry.io/6117430",
//   integrations: [new Integrations.BrowserTracing()],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

function App() {
    return (
        <div className="App">
            <AuthUserProvider>
                <ThemeProvider theme={Theme}>
                    <MessageProvider>
                        <Routes>
                            <Route index element={<PrivateRoute><Home/></PrivateRoute>}/>
                            <Route path="curriculums" element={<PrivateRoute><Curriculums/></PrivateRoute>}/>
                            <Route path="students" element={<PrivateRoute><Students/></PrivateRoute>}/>
                            <Route path="student/:id" element={<PrivateRoute><StudentDetail/></PrivateRoute>}/>
                            <Route path="student/books/:id/:year/:month"
                                   element={<PrivateRoute><StudentBooks/></PrivateRoute>}/>
                            <Route path="books/:year/:month" element={<PrivateRoute><Books/></PrivateRoute>}/>
                            <Route path="book/:id" element={<PrivateRoute><CurriculumDetail/></PrivateRoute>}/>
                            <Route path="bookHistory/:id" element={<PrivateRoute><BookHistories/></PrivateRoute>}/>
                            <Route path="attendances/:year/:month"
                                   element={<PrivateRoute><Attendances/></PrivateRoute>}/>
                            <Route path="attendance/:id" element={<PrivateRoute><AtendanceDetail/></PrivateRoute>}/>
                            <Route path="hearingSheet/:id" element={<PrivateRoute><HearingSheet/></PrivateRoute>}/>
                            <Route path="absent/:id" element={<PrivateRoute><Absent/></PrivateRoute>}/>
                            <Route path="login" element={<GenericTemplate><Login/></GenericTemplate>}/>
                            <Route path="error" element={<GenericTemplate><Error/></GenericTemplate>}/>
                            <Route path="test" element={<TestPage/>}/>
                        </Routes>
                    </MessageProvider>
                </ThemeProvider>
            </AuthUserProvider>
        </div>
    );
}

export default App;
