import { Box, Chip, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router";
import useBookHistories from "../../Hooks/useBookHistories";
import SecondButton from "../atom/Buttons/SecondButton";


const BookHistories = () =>{
  const { fetchRequest,bookHistories,isLoading } = useBookHistories();
  const { id } = useParams();
  useEffect(() => {
    if(id) {
    fetchRequest(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  if (isLoading){
    return <div>loading...</div>
  }
  
  return (
    <>
      <Grid container>
        <Grid item xs={10}>
      <h1>予約履歴
      </h1>
        </Grid>
        <Grid item xs={2}>
          <SecondButton label="戻る" onClick={() => window.history.back()} />
        </Grid>
      </Grid>
      {bookHistories && bookHistories.length === 0 && (<Typography sx={{marginTop: '1em'}}>予約履歴はありません。</Typography>)}
      {bookHistories.length > 0 && (
        <Box sx={{ marginBottom: "5en" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>予約日時</TableCell>
                <TableCell>実行者</TableCell>
                <TableCell>名前</TableCell>
                <TableCell sx={{textAlign:'center'}}>状況</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bookHistories.map((history) => (
                <TableRow key={history.booking_id}>
                  <TableCell>{history.created_at}</TableCell>
                  <TableCell>{history.booking_user}</TableCell>
                  <TableCell>{history.user}</TableCell>
                  <TableCell sx={{textAlign:'center'}}>
                    <Chip label={history.booking_status} color={history.booking_status === '予約' ? 'primary' : 'secondary'} />
                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>)}
    </>
  );
}
export default BookHistories;
